import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contrasena-update',
  templateUrl: './contrasena-update.page.html',
  styleUrls: ['./contrasena-update.page.scss'],
})
export class ContrasenaUpdatePage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
